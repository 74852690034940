import * as React from "react";
import PageTitle from "../components/elements/PageTitle";
// import DevelopersItem from "../components/elements/DevelopersItem";
// import DevelopersSection from "../components/sections/DevelopersSection";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
// import DataDomainImg from "../images/src_assets_developers_data-domain.jpg";
// import APIKeysImg from "../images/src_assets_developers_api-keys.jpg";
// import JavaImg from "../images/src_assets_developers_java.jpg";
import Title from "../components/elements/Title";
import FiscalizationApiSection from "../components/sections/FiscalizationApiSection";
import FiscalizationApiItem from "../components/elements/FiscalizationApiItem";
import { graphql } from 'gatsby'

const ccuDocsPage = ({ data }) => {
  const legalApiItems = data?.allMdx?.nodes?.filter(node => node.slug.startsWith('ccu-docs/legal'))
  const technicalApiItems = data?.allMdx?.nodes?.filter(node => node.slug.startsWith('ccu-docs/technical'))
  return (
      <div className="bg-white">
        <PageTitle
          pageTitle={"Cloud Control Unit Docs"}
          description={``}
        />
        <Header />
        <Title title="Cloud Control Unit Docs" />
        {legalApiItems?.length > 0 && 
          <div className="pt-16 lg:pt-24">
            <FiscalizationApiSection title={"Legal"} >
              {
                legalApiItems?.map(node => (
                  <FiscalizationApiItem
                    key={node.slug}
                    articleUrl={node.slug}
                    image={node.frontmatter.image}
                    title={node.frontmatter.title}
                    description={node.frontmatter.description}
                  />
                ))
              }
            </FiscalizationApiSection>
          </div>
        }
        {technicalApiItems?.length > 0 && 
          <div className="pt-16 lg:pt-24">
            <FiscalizationApiSection title={"Technical"} >
              {
                technicalApiItems?.map(node => (
                  <FiscalizationApiItem
                    key={node.slug}
                    articleUrl={node.slug}
                    image={node.frontmatter.image}
                    title={node.frontmatter.title}
                    description={node.frontmatter.description}
                  />
                ))
              }
            </FiscalizationApiSection>
          </div>
        }
        <Footer />
      </div>
    );
}

export const query = graphql`
  query {
    allMdx {
      nodes {
        slug
        frontmatter {
          image
          title
          description
        }
      }
    }
  }
`
export default ccuDocsPage;
